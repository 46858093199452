import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { DeprecatedMaskedInput } from "common/form/inputs/masked_input";
import phoneComputer from "assets/images/phone_computer.svg";

import Button from "../common/button";

export default class Authentication extends Component {
  constructor(props) {
    super(props);

    this.pin = null;
    this.state = { canClick: false, error: false, fullName: null };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    const { signerIdentityService: service } = this.props;

    service
      .getFullName()
      .then((fullName) => {
        this._isMounted && this.setState({ fullName });
      })
      .catch(() => {});
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handlePinChange = (event) => {
    this.pin = event.target.value;
    this.setState({ canClick: this.pin.length === 4 });
  };

  handleClick = () => {
    const { onSuccess, signerIdentityService: service, captureAnalytics } = this.props;
    const signerIdentityId = service.getId();

    captureAnalytics.trackAuthorizationAttempt({ signerIdentityId, pin: this.pin });
    service
      .setPIN(this.pin)
      .createPhotoIdentification()
      .then(() => {
        captureAnalytics.trackAuthorizationSuccess({ signerIdentityId });
        onSuccess();
      })
      .catch(() => {
        this._isMounted && this.setState({ error: true });
      });
  };

  render() {
    const { canClick, error, fullName } = this.state;
    const inputCx = classnames("AuthenticationBody--Form--Input", {
      "AuthenticationBody--Form--Input__error": error,
    });

    return (
      <div className="AuthenticationBody">
        <img className="AuthenticationBody--Picture" src={phoneComputer} alt="Smartphone" />
        <span
          className="AuthenticationBody--Text AuthenticationBody--Text--Name"
          data-automation-id="signer-identity-full-name"
        >
          Hi{fullName && `, ${fullName}`}!
        </span>
        <span className="AuthenticationBody--Text AuthenticationBody--Text--Instructions">
          Please enter the 4-digit code on your computer screen to begin your ID retake.
        </span>
        <div className="AuthenticationBody--Form">
          <DeprecatedMaskedInput
            className={inputCx}
            maskType="number"
            placeholder="____"
            onChange={this.handlePinChange}
            maxlength={4}
          />
          {error && <span className="AuthenticationBody--Form--Error">Incorrect code</span>}
        </div>
        <Button
          className="AuthenticationBody--Button"
          action={canClick}
          disabled={!canClick}
          onClick={this.handleClick}
          automationId="pin-code-submit"
          large
        >
          Get started
        </Button>
      </div>
    );
  }
}

Authentication.propTypes = {
  captureAnalytics: PropTypes.shape({
    trackAuthorizationAttempt: PropTypes.func.isRequired,
    trackAuthorizationSuccess: PropTypes.func.isRequired,
  }).isRequired,
  signerIdentityService: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
